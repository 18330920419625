import React from "react";
import { Textarea, Label, Input } from '../common';
import Button from '../Button';
import './Forms.css';

export const ProfileForm = ({ age, bio, submit }) => {
  return (
    <form onSubmit={submit} className="form">
      <Label label="Age">
        <Input className="" type="number" defaultValue={age} name="age"/>
      </Label>
      <Label label="Bio">
        <Textarea className="" name="bio" defaultValue={bio} placeholder="Tell a few words about yourself" resize={false} maxLength={120} note="Maximum 120 symbols" />
      </Label>
      <Button className="ButtonAccent form-submit">Save</Button>
    </form>
  );
};
