import React from "react";
import { useNavigate } from 'react-router-dom';
import { PiGenderFemale, PiGenderMale} from "react-icons/pi";

import Button from "../Button";
import './GroupWidget.css';

const GroupWidget = ({group}) => {
    const navigate = useNavigate();

    return (
        <>
        {!group ? (
            <Button className="GroupWidgetJoinButton" onClick={() => {
                navigate(`/groups`)
            }}>Join group<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L1 11" stroke="#C8C7CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></Button>
        ) : (
            <div className="GroupWidget" onClick={() => navigate(`/groups/${Math.abs(group.id)}`)}>
                <div className="GroupWidgetLeft">
                    <img src={`https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/group/${group.id}.jpg`} className="GroupWidgetLogo" width="48" alt={group.title} />
                    <div className="GroupWidgetName">{group.title}</div>
                </div>
                <div className="GroupWidgetRight">
                    {false & (
                        <div className="GroupWidgetMetrics">
                            <div><PiGenderMale/> 125</div>
                            <div><PiGenderFemale/> 300</div>
                        </div>
                    )}
                </div>
            </div>  
        )}
        </>
        
    )
}

export default GroupWidget;