import React from "react";
import './QR.css';

const QR = () => {
    return (
        <div className="QR">
            <div className="QRImage"></div>
        </div>
    )
}

export default QR;