import React, { useContext, useState, useEffect, useCallback } from "react";
import './ProfilePage.css';
import { useNavigate } from 'react-router-dom';
import useBackButton from "../../hooks/useBackButton";
import { DataContext } from '../../services/DataContext';
import { checkImageExists } from '../../utils/images';
import MalePlaceholder from '../../assets/images/avatar/men-placeholder.webp';
import FemalePlaceholder from '../../assets/images/avatar/women-placeholder.webp';
import { PiSpinnerGap } from "react-icons/pi";
import { ProfileForm } from '../../components/Forms/ProfileForm';
import { toast } from 'react-toastify';
import useApi from '../../services/api';

const ProfilePage = () => {
    const { data } = useContext(DataContext);
    const navigate = useNavigate();
    const { updateUserData } = useApi();
    const { user: { telegram_id, first_name, age, sex, bio } } = data;
    const [loaded, setLoaded] = useState(false);
    const [userImage, setUserImage] = useState(null);
    useBackButton(() => navigate(-1));

    useEffect(() => {
        const fetchData = async () => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => {
                setUserImage(exists ? imageUrl : sex === 'male' ? MalePlaceholder : FemalePlaceholder);
                setLoaded(true);
            });
        }
        
        fetchData();
    }, [sex, telegram_id]);

    const submit = useCallback(async (e) => {
        e.preventDefault();

        if (e.target.age.value <= 0) {
            toast.error(`We don't think it's your real age. Check it again`);
            return;
        }

        if (e.target.age.value === `${age}` && e.target.bio.value === (bio || "")) {
            toast.success(`No changes. That's ok too`);
            return;
        }

        const { success } = await updateUserData(telegram_id, e.target.age.value, e.target.bio.value);
        if (success) toast.success(`Your profile data was updated`);
    }, [telegram_id, age, bio, updateUserData]);

    return (
        <div className="Page PageProfile">
            {
                loaded ? <>
                <div className="PageProfileHeader">
                    <div style={{ backgroundImage: `url(${userImage})` }} className="PageProfileImage">
                        <div className="PageProfileInfo">
                            <h3 className="PageProfileName">{first_name}</h3>
                        </div>
                    </div>
                </div>
                <div className="PageProfileForm">
                    <ProfileForm age={age} bio={bio} submit={submit} />
                </div>
            </> : (
                    <div className="cardWrapper">
                        <div className="card cardLoading">
                            <div className="cardEmptyInfo">
                                <h3 className="cardLoadingIcon">
                                    <PiSpinnerGap size={48} />
                                </h3>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ProfilePage;