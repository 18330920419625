import { useCallback } from 'react';
import { checkImageExists } from '../utils/images';
import MalePlaceholder from '../assets/images/avatar/men-placeholder.webp';
import FemalePlaceholder from '../assets/images/avatar/women-placeholder.webp';
import { SWIPE_TYPE } from './constants';
import { toast } from 'react-toastify';

import { useSupabase } from './SupabaseContext';

const useApi = () => {
    const supabase = useSupabase();

    const fetchUserData = useCallback(async (telegramId) => {
        const { data, error } = await supabase
            .from('users')
            .select(` 
                *,
                group: groups(
                    *,
                    users(count)
                )
            `)
            .eq("telegram_id", telegramId)

        if (error) {
            console.log('Error fetching users', error)
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
        }
        else {
            return data
        }
    }, [supabase])

    const updateUserData = useCallback(async (telegramId, age, bio) => {
        const { error } = await supabase
            .from('users').update({ age: Number(age), bio })
            .eq('telegram_id', telegramId);
        
        if (error) {
            console.log(error);
            toast.error(error.message);
            return error
        }

        return { success: true }
    }, [supabase])

    const fetchGroupData = useCallback(async (groupId) => {
        if (!groupId) {
            return null
        }

        const { data, error } = await supabase
            .from('groups')
            .select(` 
                *,
                users(telegram_id, total_coins, first_name, is_premium),
                total_users: users(count)
            `)
            .order('total_coins', { foreignTable: 'users', ascending: false })
            .eq("id", groupId)
            .limit(50, { referencedTable: 'users'})
            .single()

        if (error) console.log('Error fetching users', error)
        else {
            return data
        }
    }, [supabase])

    const fetchGroups = useCallback(async () => {
        const { data, error } = await supabase
            .from("groups")
            .select(`
                *,
                users: users(count)
            `)
        if (error) console.log('Error fetching groups', error)
        else {
            return data
        }
    }, [supabase])

    const getRandomUsers = useCallback(async (sex, group_id) => {
        const { data } = await supabase.rpc('get_cards_by_group', {my_sex: sex, my_group_id: group_id});

        const imageCheckedData = data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => ({
                ...item,
                avatar: exists ? imageUrl : item.sex === 'male' ? MalePlaceholder : FemalePlaceholder
            }));
        });

        let response = [];

        await Promise.all(imageCheckedData).then(people => {
            response = people;
        });

        return response;
    }, [supabase])


    const fetchLikes = useCallback(async (telegramId) => {
        const { data, error } = await supabase
            .from('swipes')
            .select(`
            id, 
            user_id,
            target_user_id,
            status, 
            users ( id, telegram_id, first_name, last_name, age, slug )
        `).match({ status: SWIPE_TYPE.LIKE, target_user_id: telegramId })


        if (error) {
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return error
        }

        const imageCheckedData = data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg`;

            return checkImageExists(imageUrl).then(exists => ({
                avatar: exists ? imageUrl : item.sex === 'male' ? MalePlaceholder : FemalePlaceholder,
                likeId: item.id,
                firstName: item.users.first_mame,
                lastName: item.users.last_name,
                age: item.users.age,
                tgName: item.slug
            }));
        });

        return imageCheckedData;
    }, [supabase])

    const fetchMatches = useCallback(async (telegramId) => {
        const { data, error } = await supabase
            .from('swipes')
            .select(`
            id, 
            user_id,
            target_user_id,
            status, 
            users ( id, telegram_id, first_name, last_name, age, slug )
        `).match({ status: SWIPE_TYPE.MATCH, target_user_id: telegramId })


        if (error) {
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return error
        }

        const imageCheckedData = data.map(item => {
            const imageUrl = `https://vfonzbsnatcrbykoagvk.supabase.co/storage/v1/object/public/images/user/${item.telegram_id}.jpg`;

            console.log(item);

            return checkImageExists(imageUrl).then(exists => ({
                avatar: exists ? imageUrl : item.sex === 'male' ? MalePlaceholder : FemalePlaceholder,
                likeId: item.id,
                firstName: item.users.first_name,
                lastName: item.users.last_name,
                age: item.users.age,
                tgName: item.users.slug
            }));
        });

        return imageCheckedData;
    }, [supabase])

    const setDislike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.DISLIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setLike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.LIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    // COMMENT: it's for v2
    const setSuperlike = useCallback(async (id, targetId) => {
        const { error } = await supabase
            .from('swipes')
            .insert({ user_id: id, target_user_id: targetId, status: SWIPE_TYPE.SUPERLIKE })

        if (error) {
            // TODO: toast and stop swipe
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setMatch = useCallback(async (id) => {
        const { error } = await supabase
            .from('swipes')
            .update({ status: SWIPE_TYPE.MATCH })
            .eq('id', id)

        if (error) {
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
            return { success: false }
        }

        return { success: true }
    }, [supabase])

    const setDismatch = useCallback(async (id) => {
        const { error } = await supabase
            .from('swipes')
            .delete()
            .eq('id', id)

        if (error) {
            console.log(error);
            toast(<div className="toast-msg toast-msg--error">{error.message}</div>);
        }
    }, [supabase])

    const fetchReferrals = useCallback(async (telegramId) => {
        const { data, error } = await supabase
            .from('users')
            .select('id, telegram_id, first_name, is_premium')
            .eq("referrer_id", telegramId)

        if (error) {
            console.log('Error fetching users', error)
            toast(<div className="toast-msg toast-msg--error">Error fetching users</div>);
        }
        else {
            return data
        }
    }, [supabase])

    const saveClicks = useCallback(async (count) => {
        const { data, error } = await supabase.rpc('save_clicks', { coins: count })

        if (error) {
            console.log(error);
        } else {
            return data;
        }
    }, [supabase])

    const joinGroup = useCallback(async (telegramId, groupId, title) => {
        const id = toast.loading(groupId ? "Joining...": "Leaving...")
        const { data, error } = await supabase.from('users')
            .update({ group_id: groupId })
            .eq('telegram_id', telegramId)
            .select(` 
                *,
                group: groups(*)
            `).single();

        if (error) {
            console.log(error);
            toast.update(id, {
                render: groupId ? "Joining error. Try again later": "Leaving error. Try again later",
                type: "error",
                autoClose: 2000,
                isLoading: false
            })
        } else {
            toast.update(id, {
                render: groupId ? `You joined ${title} group!` : `You left ${title} group!`,
                type: data.success ? "success" : "info",
                autoClose: 2000,
                isLoading: false
            })
            return data;
        }
    }, [supabase])

    return {
        // Users
        fetchReferrals,
        fetchUserData,
        updateUserData,
        getRandomUsers,
        // Likes
        fetchLikes,
        fetchMatches,
        setDislike,
        setLike,
        setSuperlike,
        setMatch,
        setDismatch,
        // Clicker
        saveClicks,
        // Groups
        fetchGroups,
        fetchGroupData,
        joinGroup,
    }
}

export default useApi;