import React, { useState, useCallback } from "react";
import { PiHeartStraight, PiX, PiTelegramLogo, PiHeartBreak} from "react-icons/pi";
import './LikesList.css';
import useTelegram from "../../hooks/useTelegram";
import useApi from "../../services/api";
import { SWIPE_TYPE } from '../../services/constants';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { TbHearts } from "react-icons/tb";

const LikesList = ({ users, type = SWIPE_TYPE.LIKE, updateList }) => {
    const { tg } = useTelegram();
    const { setDismatch, setMatch } = useApi();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    const openModal = useCallback((user) => {
        setSelectedUser(user);
        setModalIsOpen(true);
    }, [setSelectedUser, setModalIsOpen]);

    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    const acceptLike = useCallback(async (item) => {
        await setMatch(item.likeId);
        await updateList();
        toast(<div className="toast-msg"><TbHearts className="toast-icon" /> Wow it's a match!</div>);
    }, [updateList, setMatch]);

    const declineLike = useCallback(async (item) => {
        await setDismatch(item.likeId);
        await updateList();
        toast(<div className="toast-msg"><PiHeartBreak className="toast-icon" /> Ok. You will find someone better :)</div>);
    }, [updateList, setDismatch]);

    return (
        <div className="LikesList">
            {
                users.map(item => (
                    <div key={item.likeId} className="LikesListItem">
                        <div className="LikesListItemInner">
                            <div className="LikesListItemImage">
                                <img className="LikesListItemAvatar" src={item.avatar} alt={`${item.firstName} ${item.lastName}`} onClick={() => openModal(item)} />
                            </div>
                            <div className="LikesListItemContent">
                                <div className="LikesListItemContentTitle" onClick={() => openModal(item)}>{item.firstName}, {item.age}</div>
                            </div>
                            {
                                type === SWIPE_TYPE.LIKE && (
                                    <div className="LikesListItemActions">
                                        <button onClick={() => declineLike(item)} className="LikesListBtn LikesListBtnSkip">
                                            <PiX className="icon" />
                                        </button>
                                        <button onClick={() => acceptLike(item)} className="LikesListBtn LikesListBtnMatch">
                                            <PiHeartStraight className="icon" />
                                        </button>
                                    </div>
                                )
                            }
                            {
                                type === SWIPE_TYPE.MATCH && (
                                    <div className="LikesListItemActions">
                                        <button
                                            onClick={() => {
                                                tg.openTelegramLink(`https://t.me/${item.tgName}`);
                                                tg.close();
                                            }}
                                            className="LikesListBtn LikesListBtnMatch"
                                        >
                                            <PiTelegramLogo className="icon" />
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ))
            }
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="User Photo"
                    ariaHideApp={false}
                    className="LikesListModal"
                    overlayClassName="LikesListModalOverlay"
                >
                    <button onClick={closeModal} className="LikesListModalClose">
                        <PiX className="icon" />
                    </button>
                    {
                        selectedUser && (
                            <div className="LikesListModalContent">
                                <div className="LikesListModalImage">
                                    <img className="LikesListModalAvatar" src={selectedUser.avatar} alt={`${selectedUser.firstName} ${selectedUser.lastName}`} />
                                </div>
                                <div className="LikesListModalText">
                                    <div className="LikesListItemTitle">{selectedUser.firstName}, {selectedUser.age}</div>
                                </div>
                            </div>
                        )
                    }
                </Modal>
        </div>
    )
}

export default LikesList;