import React, { useState, useCallback, useContext, useEffect, useRef } from "react";
import TinderCard from 'react-tinder-card'
import './Cards.css';
import { PiHeartStraight, PiX, PiSpinnerGap } from "react-icons/pi";
import useApi from "../../services/api";
import { DataContext } from '../../services/DataContext';
import { arraysOfObjectsAreEqual } from '../../utils/arrays.js';

const Cards = ({ groupId }) => {
    const { data, initData } = useContext(DataContext);
    const { user } = data;
    const { setLike, setDislike, getRandomUsers } = useApi();
    const cardRefs = useRef([]);

    const [cards, setCards] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOver, setIsOver] = useState(false);

    const loadUsers = useCallback(async () => {
        if (isOver) return [];
        setIsLoading(true);

        if (!loaded) {
            const storageCards = localStorage.getItem('cupidoCards');
            const parsedCards = storageCards && JSON.parse(storageCards);
            if (parsedCards && parsedCards.length > 0) {
                setCards(parsedCards);
                setLoaded(true);
                setIsLoading(false);
                return [];
            }
        }

        const people = await getRandomUsers(user.sex, user.group_id);

        if (people.length > 0 && !arraysOfObjectsAreEqual(cards, people)) {
            setCards([...people, ...cards]);
            setLoaded(true);
            setIsLoading(false);
        } else {
            setIsOver(true);
            setLoaded(true);
            setIsLoading(false)
            return []
        }
    }, [isOver, setIsLoading, getRandomUsers, setCards, setLoaded, setIsOver, cards, user.sex, user.group_id, loaded]);

    useEffect(() => {
        if (!isLoading && !isOver && cards.length - 1 < 3) {
            loadUsers();
        }
        return () => {
            // Save current cards in storage
            localStorage.setItem('cupidoCards', JSON.stringify(cards));
        }
    }, [cards, isOver, isLoading, loadUsers]);

    const onSwipe = useCallback(async (direction, card) => {
        let response;
        if (direction === 'right') response = await setLike(user?.telegram_id, card?.telegram_id);
        if (direction === 'left') response = await setDislike(user?.telegram_id, card?.telegram_id);

        if (response && response.success) {
            await initData(); // Reload balance
            setTimeout(() => setCards(cards.slice(0, -1)), 500);
        } else {
            if (cards.length) {
                const cardRef = cardRefs.current[cards.length - 1];
                if (cardRef) {
                    cardRef.restoreCard();
                }
            };
        }
    }, [user?.telegram_id, initData, setCards, cards, cardRefs, setLike, setDislike]);

    const swipe = useCallback((dir) => {
        if (cards.length) {
            const cardRef = cardRefs.current[cards.length - 1];
            if (cardRef) {
                cardRef.swipe(dir);
            }
        };
    }, [cards, cardRefs]);

    return (
        <div className="cardList">
            {
                loaded ? (
                    <div className="cardWrapper">
                        {
                            cards?.map((card, index) => (
                                <TinderCard ref={ref => ref && (cardRefs.current[index] = ref)} onSwipe={(dir) => onSwipe(dir, card)} preventSwipe={['up', 'down']} className="swipe" key={index}>
                                    <div style={{ backgroundImage: `url(${card.avatar})` }} className={`card ${index === cards.length - 1 ? 'cardActive' : ''}`}>
                                        <div className="cardInfo">
                                            <h3 className="cardInfoTitle">{card.first_name}, {card.age}</h3>
                                        </div>
                                    </div>
                                </TinderCard>
                            ))
                        }
                        <div className="card cardEmpty">
                            <div className="cardEmptyInfo">
                                <h3 className="cardEmptyTitle">No more lovers?<br /><br />Try to come back later or search in another group</h3>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="cardWrapper">
                        <div className="card cardLoading">
                            <div className="cardEmptyInfo">
                                <h3 className="cardLoadingIcon">
                                    <PiSpinnerGap size={48} />
                                </h3>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className='buttons'>
                <button onClick={() => swipe('left')} className="btn btnSkip">
                    <PiX className="icon" />
                </button>
                <button onClick={() => swipe('right')} className="btn btnMatch">
                    <PiHeartStraight className="icon" />
                </button>
            </div>
        </div>
    )
}

export default Cards;