import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import useBackButton from "../../hooks/useBackButton";
import { DataContext } from '../../services/DataContext';
import Navigation from "../../components/Navigation";
import Clicker from "../../components/Clicker";
// import GroupWidget from "../../components/GroupWidget";
import ScoreBar from "../../components/ScoreBar";
import PlaceAndScore from "../../components/PlaceAndScore";

import './BalancePage.css';

const BalancePage = () => {
    const navigate = useNavigate();
    const { data: { user }, setData: setUserData } = useContext(DataContext);
    const [data, setData] = useState({
        multitap: user.multiple_clicks,
        balance: user.balance,
        dailyCoins: user.daily_coins,
        miningCoins: 0
    })
    useBackButton(() => navigate(-1));

    useEffect(() => {
        if (data.miningCoins === 0) {
            setUserData(store => ({
                ...store, user: {
                    ...user,
                    balance: data.balance,
                    daily_coins: data.dailyCoins
                }
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.miningCoins])

    return (
        <div className="Page PageBalance">
            {/* <GroupWidget group={user.group} /> */}
            <PlaceAndScore score={data.balance} />
            <Clicker data={data} setData={setData} />
            <ScoreBar limitCoins={user.limit_coins} dailyCoins={data.dailyCoins} />
            <Navigation />
        </div>
    )
}

export default BalancePage;