import React, { useContext } from "react";
import './MainPage.css';
// import Button from '../../components/Button';
import Cards from '../../components/Cards';
import Balance from '../../components/Balance';
import GroupWidget from "../../components/GroupWidget";
import { DataContext } from '../../services/DataContext';
import Navigation from "../../components/Navigation/Navigation";


const MainPage = () => {
    const { data } = useContext(DataContext);
    return (
        <div className="Page PageMain">
            <div className="PageMainHeader">
                <GroupWidget group={data.user?.group}/>
                {data.user && data.user?.balance !== undefined ? <Balance amount={data.user?.balance} /> : <></>}
            </div>
            <Cards groupId={data.user?.group?.id} />
            <div className="PageMainFooter">
                <Navigation />
            </div>
        </div>
    )
}

export default MainPage;