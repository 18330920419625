import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom'; 
import MainPage from './pages/MainPage';
import FriendsPage from './pages/FriendsPage';
import ProfilePage from './pages/ProfilePage';
import BalancePage from './pages/BalancePage';
import RatingPage from './pages/RatingPage';
import Groups from './pages/Groups';
import GroupDetailsPage from './pages/GroupDetailsPage';
import Matches from './pages/Matches';
import SentLikes from './pages/SentLikes';
import ReceivedLikes from './pages/ReceivedLikes';
import { ToastContainer } from 'react-toastify';
import { initializeSupabase } from './services/supabaseClient';
import { SupabaseProvider } from './services/SupabaseContext';
import { DataProvider } from './services/DataContext';
import Loader from './components/Loader';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [supabaseClient, setSupabaseClient] = useState(null);

  useEffect(() => {
    initializeSupabase().then(client => {
      setSupabaseClient(client);
    });
  }, []);

  if (!supabaseClient) return <Loader />;

  return (
    <SupabaseProvider supabaseClient={supabaseClient}>
      <DataProvider>
        <div className="App">
          <Routes>
            <Route index element={<MainPage  />} /> 
            <Route path="/balance" element={<BalancePage />} />
            <Route path="/rating" element={<RatingPage />} />
            <Route path="/friends" element={<FriendsPage />} />
            <Route path="/matches" element={<Matches />} />
            <Route path="/sentlikes" element={<SentLikes />} />
            <Route path="/receivedlikes" element={<ReceivedLikes />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/groups/:id" element={<GroupDetailsPage />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Routes> 
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </DataProvider>
    </SupabaseProvider>
  );
}

export default App;
