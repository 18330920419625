import React, { createContext, useState, useEffect, useCallback } from 'react';
import useTelegram from '../hooks/useTelegram';
import useApi from '../services/api';
import Loader from "../components/Loader";
import QR from "../components/QR";

// Создание контекста
export const DataContext = createContext();

// Создание провайдера контекста
export const DataProvider = ({ children }) => {
  const [data, setData] = useState({user: null});

  // Переносим инициализацию юзера прям в контекст
  const {tg, user: tgUser } = useTelegram();
  const { fetchUserData } = useApi();

  const initData = useCallback(async () => {
    const user = await fetchUserData(tgUser.id);
    setData(data => ({...data, user: user[0]}))
  }, [fetchUserData, setData, tgUser.id])

  // Значение, передаваемое в контекст
  const value = { data, setData, initData };

  useEffect(() => {
    if (!data.user) initData();
  }, [data, initData])  


  const isMobile = ["android", "android_x", "ios"].indexOf(tg.platform) >= 0;

  if (!isMobile) {
    return <QR />
  }

  if (!data.user) {
    return <Loader/>
  }


  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};