import React, {useContext} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { DataContext } from '../../services/DataContext';

import useBackButton from "../../hooks/useBackButton";
import useGroupInfo from "../../hooks/useGroupInfo";

import './GroupDetailsPage.css';
import Button from "../../components/Button";
import UsersList from "../../components/UsersList";
import Loader from "../../components/Loader";
import useTelegram from "../../hooks/useTelegram";
import useApi from "../../services/api";

const GroupDetailsPage = () => {
    const { data: { user }, setData: setUserData } = useContext(DataContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const { tg } = useTelegram();
    const { joinGroup } = useApi();
    const group = useGroupInfo(id);

    useBackButton(() => navigate(-1));

    const handleJoinGroupClick = async (groupId) => {
        const userData = await joinGroup(user.telegram_id, groupId, group.title);
        setUserData(store => ({
            ...store, user: userData
        }))
        navigate("/");
    }

    if (!group) return <Loader />

    return (
        <div className="Page PageGroupDetails">
            <div className="PageGroupDetailsInner">
                <div className="PageGroupDetailsCard" onClick={() => {
                    tg.openTelegramLink(`https://t.me/${group.slug}`);
                    tg.close();
                }}>
                    <div className="PageGroupDetailsCardAvatar" style={{backgroundImage: `url("https://jjvnmoyncmcewnuykyid.supabase.co/storage/v1/object/public/images/group/${group.id}.jpg")`}}></div>
                    <div className="PageGroupDetailsCardTitle">
                        {group.title}
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 16.5V17.5C20 19.1569 18.6569 20.5 17 20.5H7C5.34315 20.5 4 19.1569 4 17.5V7.5C4 5.84315 5.34315 4.5 7 4.5H8" stroke="#EBEBF5" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"></path>
                            <path d="M13 4.5H16H19.999C19.9996 4.5 20 4.50045 20 4.501V8.5V11.5" stroke="#EBEBF5" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round"></path>
                            <path d="M11.2929 11.7929C10.9024 12.1834 10.9024 12.8166 11.2929 13.2071C11.6834 13.5976 12.3166 13.5976 12.7071 13.2071L11.2929 11.7929ZM19.2929 3.79289L11.2929 11.7929L12.7071 13.2071L20.7071 5.20711L19.2929 3.79289Z" fill="#EBEBF5" fillOpacity="0.6"></path>
                        </svg>
                    </div>
                    <div className="PageGroupDetailsCardDescription">{group.description}</div>
                </div>
                <div className="PageGroupDetailsInfo">
                    <div className="PageGroupDetailsInfoIndicators">
                        {/* тут необходимо показать кол-во участников */}
                    </div>
                    <div className="PageGroupDetailsInfoActions">
                        <Button className="ButtonAccent PageGroupDetailsInfoActionsButton" onClick={() => {
                            tg.openTelegramLink(`https://t.me/CupidoAppBot_bot?start=getInviteLink_${user.telegram_id}_${group.id}`);
                            tg.close();
                        }}>Invite a friend</Button>
                        <Button className="PageGroupDetailsInfoActionsButton" onClick={() => handleJoinGroupClick(user.group_id === Number(id) ? null : group.id)}>
                            {user.group_id === Number(id) ? "Leave group" : "Join group"}
                        </Button>
                        {/* <Button className="PageGroupDetailsInfoActionsButton" onClick={() => navigate("/rating")}>Boost</Button> */}
                    </div>
                </div>
                <div className="PageGroupDetailsLeaderboard">
                    <UsersList users={group.users} type="group"/>
                </div>
            </div>
        </div>
    )
}

export default GroupDetailsPage;